import { deleteProduct, deleteProducts, getProducts } from '../api/products';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../components/Button';
import Card from '../components/Card';
import CheckBox from '../components/Checkbox';
import chunk from '../utils/chunk';
import {
  setDeleteProductsBatchActions,
  setDeleteTestProducts,
} from '../actions/products';
import { useTranslation } from 'react-i18next';

const ClearProduct = ({ checkTimeRequest }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const useBatchAction = useSelector((state) => state.products.clear.batch);
  const deleteTestProducts = useSelector(
    (state) => state.products.clear.testProducts
  );
  const products = useSelector((state) => {
    if (deleteTestProducts)
      return state.products.collections.filter((prod) =>
        prod.tags.includes('Dummy')
      );

    return state.products.collections;
  });

  const onClearProductsHandler = async (e) => {
    checkTimeRequest({ start: true });
    const prodIds = products.map((product) => product._id);

    if (useBatchAction) {
      const batchPromises = chunk(prodIds, 600).map((chunkProdIds) =>
        deleteProducts(chunkProdIds)
      );
      await Promise.all(batchPromises);
    } else {
      await Promise.all(prodIds.map((id) => deleteProduct(id)));
    }

    checkTimeRequest({ start: false });
  };

  const onClearProductsBatchHandler = async (e) => {
    dispatch(setDeleteProductsBatchActions(e.target.checked));
  };

  const onDeleteTestProductsHandler = async (e) => {
    dispatch(setDeleteTestProducts(e.target.checked));
  };

  return (
    <Card title={t('clearProduct.title')}>
      <Button type="button" onClick={onClearProductsHandler} style="danger">
        {t('clearProduct.actions.clear')}
      </Button>
      <CheckBox
        text={t('clearProduct.configurations.batch')}
        checked={useBatchAction}
        onChange={onClearProductsBatchHandler}
        style={{ marginTop: '10px' }}
      />
      <CheckBox
        text={t('clearProduct.configurations.remove_test_products')}
        checked={deleteTestProducts}
        onChange={onDeleteTestProductsHandler}
        style={{ marginTop: '10px' }}
      />
    </Card>
  );
};

export default ClearProduct;
