import {
  createCouponCampaign,
  updateCouponCampaignStatus,
  deleteCouponCampaign,
  updateCouponCampaignCouponsCodes,
} from '../api/couponCampaigns';
import generateCouponCampaign, {
  getCampaignCodes,
  getCampaignStatus,
} from '../utils/generateCouponCampaign';

import { useSelector } from 'react-redux';

import Button from '../components/Button';
import Card from '../components/Card';
import InputField from '../components/InputField';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const CouponCampaignContainer = ({ checkTimeRequest }) => {
  const { t } = useTranslation();
  const shop = useSelector((state) => state.shop.data);
  const listCouponCampaigns = useSelector(
    (state) => state.couponCampaigns.collections
  );
  const [couponCampaigns, setCouponCampaigns] = useState(1);

  const onCreateCouponCampaignsHandler = async (e) => {
    checkTimeRequest({ start: true });

    const arrCouponCampaigns = [...Array(couponCampaigns).keys()];

    const couponCampaignPromises = arrCouponCampaigns.map(() => {
      let status = getCampaignStatus();

      return createCouponCampaign(generateCouponCampaign(shop, status)).then(
        async (couponCampaign) => {
          await updateCouponCampaignStatus(couponCampaign._id, status);
          await updateCouponCampaignCouponsCodes(
            couponCampaign._id,
            getCampaignCodes()
          );
        }
      );
    });

    Promise.all(couponCampaignPromises).then(() => {
      checkTimeRequest({ start: false });
    });
  };

  const onChangeCouponCampaignsHandler = (e) => {
    setCouponCampaigns(parseInt(e.target.value));
  };

  const onRemoveCouponCampaignsHandler = (e) => {
    checkTimeRequest({ start: true });

    const couponCampaignPromises = listCouponCampaigns.map((couponCampaign) =>
      deleteCouponCampaign(couponCampaign._id)
    );

    Promise.all(couponCampaignPromises).then(() => {
      checkTimeRequest({ start: false });
    });
  };
  return (
    <>
      <Card title={t('coupon_campaigns.title')}>
        <InputField
          onChange={onChangeCouponCampaignsHandler}
          value={couponCampaigns}
          min="1"
          max="10"
          step="1"
        />
        <Button
          type="button"
          onClick={onCreateCouponCampaignsHandler}
          style="primary"
          margin="top"
        >
          {t('coupon_campaigns.actions.create')}
        </Button>
        <Button
          type="button"
          onClick={onRemoveCouponCampaignsHandler}
          style="danger"
          margin="left"
        >
          {t('coupon_campaigns.actions.remove')}
        </Button>
      </Card>
    </>
  );
};

export default CouponCampaignContainer;
