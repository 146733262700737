import Div from './Div';

const SelecttField = (props) => {
  let option;
  if (props.includeBlank) {
    option = <option value=""> </option>;
  }

  return (
    <>
      <Div>
        <label>{props.label}</label>
        <select onChange={props.handleChange} value={props.value}>
          {option}
          {props.options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </Div>
    </>
  );
};

export default SelecttField;
