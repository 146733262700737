import {
  GET_PRODUCTS_FAILURE,
  GET_PRODUCTS_SUCCESS,
  GET_TOTAL_PRODUCTS,
  SET_DELETE_PRODUCTS_BATCH_ACTIONS,
  SET_TOTAL_PRODUCTS,
  SET_TOTAL_VARIATIONS,
  SET_DELETE_TEST_PRODUCTS
} from '../actions/products';

const initialState = {
  collections: [],
  totalProducts: 0,
  totalVariations: 0,
  clear: {
    batch: true,
    testProducts: false
  }
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        collections: action.payload
      };
    case GET_PRODUCTS_FAILURE:
      return {
        ...state,
        collections: []
      };
    case GET_TOTAL_PRODUCTS:
      return {
        ...state,
        totalProducts: action.payload
      };
    case SET_TOTAL_PRODUCTS:
      return {
        ...state,
        totalProducts: action.payload
      };
    case SET_TOTAL_VARIATIONS:
      return {
        ...state,
        totalVariations: action.payload
      };
    case SET_DELETE_PRODUCTS_BATCH_ACTIONS:
      return {
        ...state,
        clear: {
          ...state.clear,
          batch: action.payload
        }
      };
    case SET_DELETE_TEST_PRODUCTS:
      return {
        ...state,
        clear: {
          ...state.clear,
          testProducts: action.payload
        }
      };
    default:
      return state;
  }
}

export default productsReducer;
