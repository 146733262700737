import './styles/dummy-generator.scss';

import {
  endTimeRequest,
  loadingEnd,
  loadingStart,
  setTimeTotalRequest,
  startTimeRequest,
} from './actions/request';
import { getLocations } from './api/locations';
import { getCustomers, getTotalCustomers } from './api/customer';
import { getCustomersSuccess, setTotalCustomers } from './actions/customers';
import { getLocationsSuccess, setTotalLocations } from './actions/locations';
import { getProducts, getTotalProducts, listVariations } from './api/products';
import {
  getProductsSuccess,
  setTotalProducts,
  setTotalVariations,
} from './actions/products';
import { useDispatch, useSelector } from 'react-redux';

import Info from './views/Info';
import OrderContainer from './views/OrderContainer';
import ProductContainer from './views/ProductContainer';
import LocationContainer from './views/LocationContainer';
import CouponCampaignContainer from './views/CouponCampaignContainer';
import CustomerContainer from './views/CustomerContainer';
import { getShippingZonesSuccess } from './actions/shippingZone';
import { getShop } from './api/shop';
import { listPaymentMethods } from './api/paymentMethods';
import { listShippingZones } from './api/shippingZones';
import { paymentSuccess } from './actions/payment';
import { shopDataSuccess } from './actions/shop';
import { useEffect } from 'react';
import {
  getCouponCampaigns,
  getTotalCouponCampaigns,
} from './api/couponCampaigns';
import { getCouponCampaignsSuccess } from './actions/couponCampaigns';

const App = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.request.loading);
  const totalProducts = useSelector((state) => state.products.totalProducts);
  const shippingZones = useSelector((state) => state.shippingZones.collections);

  useEffect(() => {
    getTotalProducts().then((totalProd) => {
      dispatch(setTotalProducts(totalProd));
      if (totalProd > 0) {
        getProducts().then((products) => {
          let totalVariations = 0;

          products.forEach(async (product) => {
            const variationAttributes = product.variationAttributes;

            if (variationAttributes.length > 0) {
              const variations = await listVariations(product._id);

              product.variations = variations;
              totalVariations++;
              dispatch(setTotalVariations(totalVariations));
            }
          });
          dispatch(getProductsSuccess(products));
        });
      }
    });
    getShop().then((shop) => dispatch(shopDataSuccess(shop)));
    listPaymentMethods().then((payments) => {
      const offilenPayment = payments.filter(
        (payment) => payment.activated && !payment.onlinePayment
      );

      dispatch(paymentSuccess(offilenPayment));
    });
    listShippingZones().then((shippingZOnes) =>
      dispatch(getShippingZonesSuccess(shippingZOnes))
    );
    getTotalCustomers().then((totalCustomers) => {
      dispatch(setTotalCustomers(totalCustomers));
      if (totalCustomers > 0) {
        getCustomers(totalCustomers).then((customers) => {
          dispatch(getCustomersSuccess(customers));
        });
      }
    });
    getTotalCouponCampaigns().then((totalCoupon) => {
      if (totalCoupon > 0) {
        getCouponCampaigns(totalCoupon).then((couponCampaigns) => {
          dispatch(getCouponCampaignsSuccess(couponCampaigns));
        });
      }
    });
    getLocations().then((locations) => {
      dispatch(getLocationsSuccess(locations));
    });
  }, [isLoading, dispatch]);

  useEffect(() => {
    if (!isLoading) {
      dispatch(setTimeTotalRequest());
    }

    return () => {};
  }, [isLoading, dispatch]);

  const checkTimeRequest = ({ start }) => {
    start ? dispatch(loadingStart()) : dispatch(loadingEnd());
    start ? dispatch(startTimeRequest()) : dispatch(endTimeRequest());
  };

  // TODO: Add info note when it's not possible to create a product because doesn't have shippingZones

  return (
    <>
      <div className="main-wrapper">
        {!isLoading && (
          <ProductContainer
            checkTimeRequest={checkTimeRequest}
          ></ProductContainer>
        )}
        {!isLoading && totalProducts > 0 && shippingZones.length > 0 && (
          <OrderContainer checkTimeRequest={checkTimeRequest}></OrderContainer>
        )}
        {!isLoading && (
          <CustomerContainer
            checkTimeRequest={checkTimeRequest}
          ></CustomerContainer>
        )}
        {!isLoading && (
          <CouponCampaignContainer
            checkTimeRequest={checkTimeRequest}
          ></CouponCampaignContainer>
        )}
        {!isLoading && (
          <LocationContainer
            checkTimeRequest={checkTimeRequest}
          ></LocationContainer>
        )}
        <Info></Info>
      </div>
    </>
  );
};

export default App;
