export const TRANSLATIONS_EN = {
  clearProduct: {
    actions: {
      clear: 'Clear products'
    },
    configurations: {
      batch: 'Use batch action to remove all product',
      remove_test_products: 'Delete only products created by this application'
    },
    title: 'Clear products'
  },
  info: {
    label: {
      total: 'This shop has {{totalProducts}} products and {{totalVariations}} variations',
      time: 'It took {{time}}{{display}} to complete all the requests'
    }
  },
  order:{
    actions: {
      create: 'Create',
    },
    title: 'Create Orders',
    configuration: {
      activate: 'Enable orders configuration',
      testCustomers: 'Use existing test customers',
      status: 'Order status',
      paymentMethod: 'Order offline payment',
      useCouponCampaign: 'Use coupon campaign'
    },
  },
  products: {
    regular: {
      title: 'Regular products',
      actions: {
        create: 'Create'
      }
    },
    variations: {
      title: 'Variation products',
      actions: {
        create: 'Create'
      },
      configuration: {
        independientImage: 'Add independent images to variations',
      }
    }
  },
  locations: {
    title: 'Locations',
    actions: {
      create: 'Create',
      use_as_pickup_option: 'use as pick-up option',
      delete: 'Delete test locations'
    }
  },
  customers: {
    title: 'Customers',
    actions: {
      create: 'Create',
      delete: 'Delete'
    }
  },
  coupon_campaigns: {
    title: 'Coupon Campaigns',
    actions: {
      create: 'Create',
      remove: 'Remove all'
    }
  }
};
