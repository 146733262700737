import getRandomElementFromArray from './getRandomElementFromArray';
import faker from 'faker';
import moment from 'moment';

const generateCouponCampaign = (shop, status = 'DRAFT') => {
  let dates = generateCampaignDates(status);
  return {
    name: `TestCoupon ${faker.lorem.words()}`,
    start: dates.start,
    end: dates.end,
    discounts: [ {
      type: 'CART',
      value: {
        type: 'PERCENT',
        percentageValue: -(faker.datatype.number({ min: 1, max: 9 }) / 10.0)
      }
    } ],
    maxNumberOfRedemptions: 10,
    minimumOrderValue: {
      currency: shop.currencies[faker.datatype.number(0, shop.currencies.length)],
      amount: 50
    }
  };
};

const generateCampaignDates = (status) => {
  let start, end;
  if (status === 'EXPIRED') {
    start = faker.date.recent(faker.datatype.number({ min: 10, max: 30 }), moment());
    end = faker.date.soon(faker.datatype.number({ min: 3, max: 10 }), start);
  }
  else {
    start = faker.date.recent(faker.datatype.number({ min: 5, max: 10 }), moment());
    end = faker.date.soon(faker.datatype.number({ min: 3, max: 10 }), moment());
  }
  return {
    start: start,
    end: end
  }
};

export const getCampaignStatus = () => {
  const UNITS = ['ACTIVE', 'PAUSED', 'EXPIRED', 'DRAFT'];

  return getRandomElementFromArray(UNITS);
};

export const getCampaignCodes = () => {
  const codes = ['','',''].map(() => `${faker.lorem.word()}-${Date.now()+ faker.datatype.number()}`)

  return codes;
}

export default generateCouponCampaign;
