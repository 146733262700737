import apiCall from './apiCall';

export const getPickupOptions = async () => {
  const pickup_options = await apiCall('GET', '/pickup-options');

  return pickup_options.data
};

export const createPickupOption = async (pickup_option) => {
  const resp = await apiCall('POST', '/pickup-options', JSON.stringify(pickup_option));

  return resp.data;
};
