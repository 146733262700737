const Div = ({ style, children }) => {
  return (
    <>
      <div
        className="relative"
        style={{ ...style, display: 'flex', alignItems: 'center' }}
      >
        {children}
      </div>
    </>
  );
};

export default Div;
