export const GET_COUPON_CAMPAIGNS = 'GET_COUPON_CAMPAIGNS';
export const GET_COUPON_CAMPAIGNS_SUCCESS = 'GET_COUPON_CAMPAIGNS_SUCCESS';
export const GET_COUPON_CAMPAIGNS_FAILURE = 'GET_COUPON_CAMPAIGNS_FAILURE';

export const GET_TOTAL_COUPON_CAMPAIGNS = 'GET_TOTAL_COUPON_CAMPAIGNS';
export const SET_TOTAL_COUPON_CAMPAIGNS = 'SET_TOTAL_COUPON_CAMPAIGNS';

export const getCouponCampaignsSuccess = (coupon_campaigns) => ({
  type: GET_COUPON_CAMPAIGNS_SUCCESS,
  payload: coupon_campaigns,
});

export const getCouponCampaignsFailure = (error) => ({
  type: GET_COUPON_CAMPAIGNS_FAILURE,
  payload: error,
});

export const getCouponCampaigns = () => ({
  type: GET_COUPON_CAMPAIGNS
});

export const getTotalCouponCampaigns = () => ({
  type: GET_TOTAL_COUPON_CAMPAIGNS
});

export const setTotalCouponCampaigns = (total) => ({
  type: SET_TOTAL_COUPON_CAMPAIGNS,
  payload: total,
});
