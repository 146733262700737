import {
  GET_COUPON_CAMPAIGNS_FAILURE,
  GET_COUPON_CAMPAIGNS_SUCCESS,
  GET_TOTAL_COUPON_CAMPAIGNS,
  SET_TOTAL_COUPON_CAMPAIGNS,
} from '../actions/couponCampaigns';

const initialState = {
  collections: [],
  totalCouponCampaigns: 0,
  totalVariations: 0
};

const couponCampaignsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUPON_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        collections: action.payload
      };
    case GET_COUPON_CAMPAIGNS_FAILURE:
      return {
        ...state,
        collections: []
      };
    case GET_TOTAL_COUPON_CAMPAIGNS:
      return {
        ...state,
        totalCouponCampaigns: action.payload
      };
    case SET_TOTAL_COUPON_CAMPAIGNS:
      return {
        ...state,
        totalCouponCampaigns: action.payload
      };
    default:
      return state;
  }
}

export default couponCampaignsReducer;
