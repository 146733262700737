import {
  createProduct,
  enableStockManagement,
  uploadMultipleImages,
} from '../api/products';
import generateProduct, {
  generateImage,
  generateStock,
} from '../utils/generateProduct';

import Button from '../components/Button';
import Card from '../components/Card';
import InputField from '../components/InputField';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const RegularProductContainer = ({ checkTimeRequest }) => {
  const { t } = useTranslation();
  const shop = useSelector((state) => state.shop.data);

  const [regularProducts, setRegularProducts] = useState(1);

  const onCreateProductsHandler = async (e) => {
    checkTimeRequest({ start: true });

    const arrProducts = [...Array(regularProducts).keys()];

    const productPromises = arrProducts.map(() => {
      return createProduct(generateProduct(shop)).then(async (product) => {
        const arrImages = [...Array(5).keys()].map(
          () => new Promise((resolve) => generateImage().toBlob(resolve))
        );

        const arrProductsWithImages = await Promise.all(arrImages);

        const fileNames = arrProductsWithImages.map(
          (_, index) => `Dummy-${index}.png`
        );
        const files = arrProductsWithImages.map((image) => image);

        uploadMultipleImages(product._id, fileNames, files).then(() => {});

        enableStockManagement(product._id, generateStock());
      });
    });

    Promise.all(productPromises).then(() => {
      checkTimeRequest({ start: false });
    });
  };

  const onChangeRegularProductsHandler = (e) => {
    setRegularProducts(parseInt(e.target.value));
  };

  return (
    <>
      <Card title={t('products.regular.title')}>
        <InputField
          onChange={onChangeRegularProductsHandler}
          value={regularProducts}
          min="1"
          max="1000"
          step="10"
        />
        <Button
          type="button"
          onClick={onCreateProductsHandler}
          style="primary"
          margin="top"
        >
          {t('products.regular.actions.create')}
        </Button>
      </Card>
    </>
  );
};

export default RegularProductContainer;
