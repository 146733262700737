import {
  END_TIME_REQUEST,
  LOADING_END,
  LOADING_START,
  SET_TIME_TOTAL_REQUEST,
  START_TIME_REQUEST
} from '../actions/request';

import moment from 'moment';

const initState = {
  loading: false,
  error: null,
  time: {
    start: null,
    end: null,
    total: null,
    display: 's'
  }
};

const requestReducer = (state = initState, action) => {
  switch (action.type) {
    case LOADING_START:
      return { ...state, loading: true };
    case LOADING_END:
      return { ...state, loading: false };
    case START_TIME_REQUEST:
      return { ...state, time: { ...state.time, start: action.payload } };
    case END_TIME_REQUEST:
      return { ...state, time: { ...state.time, end: action.payload, } };
    case SET_TIME_TOTAL_REQUEST:
      const timeEnd = moment(state.time.end);
      const timeStart = moment(state.time.start);
      const display = timeEnd.diff(timeStart, 'seconds') <= 0 ? 'ms' : 's';
      const total = moment(state.time.end).diff(moment(state.time.start), display, true);

      return {
        ...state,
        time: {
          ...state.time,
          total,
          display
        }
      };
    default:
      return state;
  }
};

export default requestReducer;
