import {
  FETCH_SHIPPING_ZONES,
  FETCH_SHIPPING_ZONES_FAILURE,
  FETCH_SHIPPING_ZONES_SUCCESS,
} from '../actions/shippingZone';

const initialState = {
  collections: [],
  error: null,
}

const shippingZoneReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SHIPPING_ZONES_SUCCESS:
      return {
        ...state,
        collections: action.payload,
      };
    case FETCH_SHIPPING_ZONES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default shippingZoneReducer;
