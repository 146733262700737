import './index.css';
import './locales/i18n';

import App from './App';
import Cookies from 'js-cookie';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import allReducers from './reducers';
import { createStore } from 'redux';

const store = createStore(
  allReducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

ReactDOM.render(
  <React.StrictMode>
    <link rel="stylesheet" type="text/css" href={Cookies.get('beyond-reseller-styles')} />
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
