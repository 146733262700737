import getRandomElementFromArray from './getRandomElementFromArray';
import faker from 'faker';

const generateLocation = (shop) => {
  return {
    languageCode: getLanguageCode(),
    storeCode: `TestLocation ${generateStoreCode()}`,
    locationName: 'ePages',
    companyName: faker.lorem.words(),
    primaryPhone: faker.phone.phoneNumberFormat(),
    address: {
      postalCode: faker.address.zipCode(),
      street: faker.address.streetName(),
      houseNumber: faker.datatype.number(10, 35),
      city: faker.address.city(),
      country: 'GB',
      dependentLocality: '',
      state: faker.address.state(),
    },
    googleStatus: getGoogleStatus(),
    googlePrimaryCategory: {
      displayName: 'Softwareentwickler/-hersteller',
      categoryId: 'gcid:software_company'
    },
    googleAdditionalCategories: [],
    regularHours: {
      periods: generatePeriods(faker.datatype.number({ min: 1, max: 6 }))
    }
  };
};

const generateStoreCode = () => {
  return `${Date.now()}-${faker.datatype.number({ min: 1, max: 10 })}`;
};

const getLanguageCode = () => {
  const UNITS = ['en', 'de'];

  return getRandomElementFromArray(UNITS);
};

const getGoogleStatus = () => {
  const UNITS = ['unverified', 'errors', 'verified'];

  return getRandomElementFromArray(UNITS);
};

const generatePeriods = (count) => {
  const DAYS = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
  const START = ['06', '07', '08', '09', '10'];
  const END = ['14', '15', '16', '17', '18'];
  var periods = [];
  for (let i = 0; i < count; i++) {
    let item = {
      openDay: DAYS[i],
      openTime: `${START[faker.datatype.number({ min: 0, max: 4 })]}:00`,
      closeDay: DAYS[i],
      closeTime: `${END[faker.datatype.number({ min: 0, max: 4 })]}:00`
    }
    periods.push(item);
  }
  return periods;
}

export default generateLocation;
