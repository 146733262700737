import getRandomElementFromArray from './getRandomElementFromArray';

const generateColorImage = () => {
  const color = [{
    background: '#000000',
    font: '#FFFFFF',
  },
  {
    background: '#FF0000',
    font: '#000000',
  },
  {
    background: '#FF6400',
    font: '#000000',
  },
  {
    background: '#FFA500',
    font: '#000000',
  },
  {
    background: '#FFFF00',
    font: '#000000',
  },
  {
    background: '#00FF00',
    font: '#000000',
  },
  {
    background: '#00FFFF',
    font: '#000000',
  },
  {
    background: '#0000FF',
    font: '#000000',
  },
  {
    background: '#FF00FF',
    font: '#000000',
  }];

  return getRandomElementFromArray(color);
};

export default generateColorImage;
