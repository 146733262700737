import ClearProduct from './ClearProduct';
import RegularProductContainer from './RegularProductContainer';
import VariationProductContainer from './VariationProductsContainer';
import { useSelector } from 'react-redux';

const ProductContainer = ({ checkTimeRequest }) => {
  const total = useSelector((state) => state.products.totalProducts);

  return (
    <>
      <RegularProductContainer
        checkTimeRequest={checkTimeRequest}
      ></RegularProductContainer>
      <VariationProductContainer
        checkTimeRequest={checkTimeRequest}
      ></VariationProductContainer>
      {total > 0 && (
        <ClearProduct checkTimeRequest={checkTimeRequest}></ClearProduct>
      )}
    </>
  );
};

export default ProductContainer;
