import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";
import refreshToken from './auth';

export const refreshTokenIfNeeded = async (config) => {
  if(checkToken(config.headers)) return config;

  await refreshToken();
  config.headers.Authorization = `Bearer ${Cookies.get('beyond-access-token')}`;

  return config;
}

const checkToken = (headers) => {
  const token = headers.Authorization.split(' ')[1];

  const jwt = jwt_decode(token);

  return Date.now() < (jwt.exp * 1000);
}
