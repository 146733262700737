import {
  adjustVariationAvailableStock,
  createProduct,
  enableStockManagement,
  enableVariationStockManagement,
  listVariationAttr,
  listVariationProperties,
  listVariations,
  makeDifferenciator,
  updateVariationProperties,
  uploadMultipleImages,
  uploadMultipleVariationImages,
} from '../api/products';
import generateProduct, {
  adjustAvailableStock,
  generateImage,
  generateStock,
} from '../utils/generateProduct';

import Button from '../components/Button';
import Card from '../components/Card';
import CheckBox from '../components/Checkbox';
import InputField from '../components/InputField';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const VariationProductContainer = ({ checkTimeRequest }) => {
  const { t } = useTranslation();
  const shop = useSelector((state) => state.shop.data);

  const [variationProducts, setVariationProducts] = useState(1);
  const [variationImages, setVariationImages] = useState(true);

  const onCreateVariationProductsHandler = async (e) => {
    checkTimeRequest({ start: true });

    const arrProducts = [...Array(variationProducts).keys()];

    for (let i = 0; i < arrProducts.length; i++) {
      const productStock = generateStock();
      const product = await createProduct(generateProduct(shop, true));
      const productId = product._id;

      enableStockManagement(productId, productStock);

      if (variationImages) {
        const listVariationProp = await listVariationProperties(productId);
        const body = listVariationProp._embedded['variation-properties'].map(
          (proper) => {
            proper.enabled = true;

            return proper;
          }
        );
        await updateVariationProperties(productId, body);
        const variationAttributes = await listVariationAttr(productId);

        variationAttributes._embedded.variationAttributes.map(async (attr) => {
          return await makeDifferenciator(productId, attr._id);
        });

        const variations = await listVariations(productId);

        for (let i = 0; i < variations.length; i++) {
          const arrImages = [...Array(5).keys()].map(
            (i) =>
              new Promise((resolve) =>
                generateImage(`Dummy-Variation-${i}`).toBlob(resolve)
              )
          );

          const arrProductsWithImages = await Promise.all(arrImages);

          const fileNames = arrProductsWithImages.map(
            (_, index) => `Dummy-${index}.png`
          );
          const files = arrProductsWithImages.map((image) => image);
          const variation = variations[i];

          const variationStock = adjustAvailableStock(productStock);

          enableVariationStockManagement(productId, variation._id).then(() =>
            adjustVariationAvailableStock(
              productId,
              variation._id,
              variationStock
            )
          );
          await uploadMultipleVariationImages(
            productId,
            variation._id,
            fileNames,
            files
          );
        }
      }
      const arrImages = [...Array(5).keys()].map(
        (i) =>
          new Promise((resolve) => generateImage(`Dummy-${i}`).toBlob(resolve))
      );

      const arrProductsWithImages = await Promise.all(arrImages);

      const fileNames = arrProductsWithImages.map(
        (_, index) => `Dummy-${index}.png`
      );
      const files = arrProductsWithImages.map((image) => image);

      uploadMultipleImages(productId, fileNames, files).then(() => {});
    }

    checkTimeRequest({ start: false });
  };

  const onChangeVariationProductsHandler = (e) => {
    setVariationProducts(parseInt(e.target.value));
  };

  const onChangeVariationImagesHandler = (e) => {
    setVariationImages(e.target.checked);
  };

  return (
    <Card title={t('products.variations.title')}>
      <InputField
        onChange={onChangeVariationProductsHandler}
        value={variationProducts}
        min="1"
        max="1000"
        step="10"
      />
      <br />
      <CheckBox
        text={t('products.variations.configuration.independientImage')}
        checked={variationImages}
        onChange={onChangeVariationImagesHandler}
      />
      <br />
      <Button
        type="button"
        onClick={onCreateVariationProductsHandler}
        style="primary"
      >
        {t('products.variations.actions.create')}
      </Button>
    </Card>
  );
};

export default VariationProductContainer;
