import {
  createLocation, deleteLocation,
} from '../api/locations';
import generateLocation, {
} from '../utils/generateLocation';
import generatePickupOption, {
} from '../utils/generatePickupOption';
import {
  createPickupOption,
} from '../api/pickupOptions';

import { useDispatch, useSelector } from 'react-redux';

import Button from '../components/Button';
import Card from '../components/Card';
import InputField from '../components/InputField';
import CheckBox from '../components/Checkbox';

import { useState } from 'react';
import { setUseAsPickupOption } from '../actions/locations';
import { useTranslation } from 'react-i18next';

const LocationContainer = ({ checkTimeRequest }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const listLocations = useSelector((state) => state.locations.collections);
  const shop = useSelector((state) => state.shop.data);
  const useAsPickupOption = useSelector((state) => state.locations.create.use_as_pickup_option);
  const [locations, setLocations] = useState(1);

  const onCreateLocationsHandler = async (e) => {
    checkTimeRequest({ start: true });

    const arrLocations = [...Array(locations).keys()];

    const locationPromises = arrLocations.map(() => {
      return createLocation(generateLocation(shop)).then(async (location) => {
        if (useAsPickupOption) {
          createPickupOption(generatePickupOption(shop, location._id));
        }
      });
    });

    Promise.all(locationPromises).then(() => {
      checkTimeRequest({ start: false });
    });
  };

  const onChangeLocationsHandler = (e) => {
    setLocations(parseInt(e.target.value));
  };
  const onUseAsPickupOptionHandler = async (e) => {
    dispatch(setUseAsPickupOption(e.target.checked));
  };

  const onDeleteLocationsHandler = async () => {
    checkTimeRequest({ start: true });

    const arrPromises = listLocations.map((location) =>
      deleteLocation(location._id)
    );
    await Promise.all(arrPromises);

    checkTimeRequest({ start: false });
  };
  return (
    <>
      <Card title={t('locations.title')}>
        <InputField
          onChange={onChangeLocationsHandler}
          value={locations}
          min="1"
          max="10"
          step="1"
        />
        <Button
          type="button"
          onClick={onCreateLocationsHandler}
          style="primary"
          margin="top"
        >
          {t('locations.actions.create')}
        </Button>
        <CheckBox
          style={{ marginTop: '10px' }}
          text={t('locations.actions.use_as_pickup_option')}
          checked={useAsPickupOption}
          onChange={onUseAsPickupOptionHandler}
        />
        <Button
          type="button"
          onClick={onDeleteLocationsHandler}
          style="danger"
          margin="top"
        >
          {t('locations.actions.delete')}
        </Button>
      </Card>
    </>
  );
};

export default LocationContainer;
