import getRandomElementFromArray from './getRandomElementFromArray';
import faker from 'faker';

const generatePickupOption = (shop, locationId) => {
  return {
    name: "My pickup option",
    description: "We will send you an email when your items are ready for pickup. Please bring a copy of your order confirmation.",
    taxClass: "REGULAR",
    freePickupValue: {
      currency: "EUR",
      amount: 50
    },
    fixedPrice: {
      taxModel: "GROSS",
      currency: "EUR",
      amount: 1
    },
    phoneNumberRequired: true,
    locationId: locationId
  };
};

export default generatePickupOption;
