import apiCall, { apiFileCall } from './apiCall';

import getArrayPages from '../utils/getArrayPages';

export const getProducts = async totalProducts => {
  if (!totalProducts) totalProducts = await getTotalProducts();

  const pages = getArrayPages(totalProducts, 1000);
  const products = await Promise.all(pages.map(async page => apiCall('GET', '/products', {}, { page, size: 1000 })));

  return products.reduce((acum, data) => acum.concat(data.data._embedded.products), []);
};

export const getTotalProducts = async () => {
  const resp = await apiCall('GET', '/products', {}, { size: 1 });

  return resp.data.page.totalElements;
};

export const createProduct = async (product) => {
  const resp = await apiCall('POST', '/products', JSON.stringify(product));

  return resp.data;
};

export const deleteProduct = async (productId) => {
  const resp = await apiCall('DELETE', `/products/${productId}`);

  return resp.data;
};

export const deleteProducts = async (productIds) => {
  const body = { ids: productIds };
  const resp = await apiCall('DELETE', `/products/batch`, JSON.stringify(body));

  return resp.data;
};

export const addImage = async (productId, fileName, image) => {
  const resp = await apiFileCall('POST', `/products/${productId}/images?fileName=${fileName}`, JSON.stringify(image));

  return resp.data;
};

export const addExternalImage = async (productId, fileName, image) => {
  const resp = await apiFileCall('POST', `/products/${productId}/images?fileName=${fileName}`, image);

  return resp.data;
};

export const uploadImage = async (productId, fileName, file) => {
  const resp = await apiFileCall('POST', `/products/${productId}/images?fileName=${fileName}`, file);

  return resp;
};

export const uploadMultipleImages = async (productId, fileNames, files) => {
  const formData = new FormData();
  files.forEach(file => formData.append('image', file));
  const arrFileNames = fileNames.map(fileName => {
    return { fileName: fileName }
  });

  const resp = await apiFileCall('POST', `/products/${productId}/images`, formData, arrFileNames);

  return resp;
};

export const enableStockManagement = async (productId, data) => {
  const resp = await apiCall('POST', `/products/${productId}/availability/enable-stock-management`, JSON.stringify(data));

  return resp.data;
}

export const listVariationAttr = async (productId) => {
  const resp = await apiCall('GET', `/products/${productId}/variation-attributes`);

  return resp.data;
}

export const makeDifferenciator = async (productId, variationAttrId) => {
  const resp = await apiCall('POST', `/products/${productId}/variation-attributes/${variationAttrId}/make-differentiator`, null);

  return resp.data;
}

export const listVariationProperties = async (productId) => {
  const resp = await apiCall('GET', `/products/${productId}/variation-properties`);

  return resp.data;
};

export const updateVariationProperties = async (productId, body) => {
  const resp = await apiCall('PATCH', `/products/${productId}/variation-properties`, JSON.stringify(body));

  return resp.data;
};

export const listVariations = async (productId) => {
  const resp = await apiCall('GET', `/products/${productId}/variations`);

  return resp.data._embedded.variations;
}

export const uploadMultipleVariationImages = async (productId, variationId, fileNames, files) => {
  const formData = new FormData();
  files.forEach(file => formData.append('image', file));
  const arrFileNames = fileNames.map(fileName => {
    return { fileName: fileName }
  });
  const resp = await apiFileCall('POST', `/products/${productId}/variations/${variationId}/images`, formData, arrFileNames);

  return resp;
}

export const enableVariationStockManagement = async (productId, variationId, data) => {
  const resp = await apiCall('POST', `/products/${productId}/variations/${variationId}/availability/enable-purchasability`, {});

  return resp.data;
}

export const adjustVariationAvailableStock = async (productId, variationId, data) => {
  const resp = await apiCall('POST', `/products/${productId}/variations/${variationId}/availability/adjust-available-stock`, JSON.stringify(data));

  return resp.data;
}
