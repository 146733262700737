import Cookies from 'js-cookie';
import axios from 'axios';
import { refreshTokenIfNeeded } from './interceptor';

const apiCall = async (method, url, data, options, contentType = 'application/json') => {
  const params = new URLSearchParams(options);
  const fullUrl = `${Cookies.get('beyond-api-url')}${url}`;

  axios.interceptors.request.use(refreshTokenIfNeeded);

  const config = {
    method: method,
    url: fullUrl,
    params,
    headers: {
      'Content-Type': contentType,
      'Authorization': `Bearer ${Cookies.get('beyond-access-token')}`
    },
    data
  };

  return axios(config);
};

export const apiFileCall = async (method, url, data, options) => {
  const params = Array.isArray(options) ? new URLSearchParams() : new URLSearchParams(options);
  const fullUrl = `${Cookies.get('beyond-api-url')}${url}`;

  if (Array.isArray(options)) {
    options.forEach((item) => {
      for (const key in item) {
        params.append(key, item[key]);
      }
    });
  }

  axios.interceptors.request.use(refreshTokenIfNeeded);

  const config = {
    method: method,
    url: fullUrl,
    params,
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${Cookies.get('beyond-access-token')}`
    },
    data
  };

  return axios(config)
    .then(response => {
      return response;
    })
    .catch(error => {
      setTimeout(() => {
        return apiFileCall(method, url, data, options);
      }, 1000);
    });

};

export default apiCall;

export const apiCallRetry = async (method, url, data, options, contentType = 'application/json') => {
  const params = new URLSearchParams(options);
  const fullUrl = `${Cookies.get('beyond-api-url')}${url}`;

  axios.interceptors.request.use(refreshTokenIfNeeded);

  const config = {
    method: method,
    url: fullUrl,
    params,
    headers: {
      'Content-Type': contentType,
      'Authorization': `Bearer ${Cookies.get('beyond-access-token')}`
    },
    data
  };

  return axios(config)
    .then(response => {
      return response;
    })
    .catch(error => {
      setTimeout(() => {
        return apiCallRetry(method, url, data, options);
      }, 1000);
    });
};
