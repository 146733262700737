import apiCall from './apiCall';
import getArrayPages from '../utils/getArrayPages';

export const getTotalCustomers = async () => {
  const resp = await apiCall('GET', '/customers', {}, {size:1});

  return resp.data.page.totalElements;
}

export const getCustomers = async totalCustomers => {
  if (!totalCustomers) totalCustomers = await getTotalCustomers();

  const pages = getArrayPages(totalCustomers, 1000);
  const customers = await Promise.all(pages.map(async page => apiCall('GET', '/customers', {}, { page, size: 1000 })));

  return customers.reduce((acum, data) => acum.concat(data.data._embedded.customers), []);
};

export const createCustomer = async customer => {
  const resp = await apiCall('POST', '/customers', JSON.stringify(customer));

  return resp.data;
};

export const deleteCustomer = async customerId => {
  const resp = await apiCall('DELETE', `/customers/${customerId}`);

  return resp.data;
};
