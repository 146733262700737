import { GET_SHOP_DATA, SHOP_DATA_SUCCESS } from '../actions/shop';

const initState = {
  data: {},
  error: null
}

const shopReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_SHOP_DATA:
      return {...state, error: null, data: {}};
    case SHOP_DATA_SUCCESS:
      return {...state, data: action.payload};
    default:
      return state;
  }
}

export default shopReducer;
