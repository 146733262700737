export const ENABLE_ORDER_CONFIGURATION = 'ENABLE_ORDER_CONFIGURATION';
export const DISABLE_ORDER_CONFIGURATION = 'DISABLE_ORDER_CONFIGURATION';

export const ENABLE_USE_TEST_CUSTOMERS = 'ENABLE_USE_TEST_CUSTOMERS';
export const DISABLE_USE_TEST_CUSTOMERS = 'DISABLE_USE_TEST_CUSTOMERS';

export const ENABLE_USE_COUPON_CAMPAIGNS = 'ENABLE_USE_COUPON_CAMPAIGNS';
export const DISABLE_USE_COUPON_CAMPAIGNS = 'DISABLE_USE_COUPON_CAMPAIGNS';

export const SET_ORDER_STATUS = 'SET_ORDER_STATUS';
export const SET_ORDER_PAYMENT = 'SET_ORDER_PAYMENT';

export const enableOrderConfiguration = () => ({
  type: ENABLE_ORDER_CONFIGURATION,
});

export const disableOrderConfiguration = () => ({
  type: DISABLE_ORDER_CONFIGURATION,
});

export const setOrderStatus = (status) => ({
  type: SET_ORDER_STATUS,
  payload: status
});

export const setOrderPayment = (payment) => ({
  type: SET_ORDER_PAYMENT,
  payload: payment
});

export const enableUseTestCustomers = () => ({
  type: ENABLE_USE_TEST_CUSTOMERS,
});

export const disableUseTestCustomers = () => ({
  type: DISABLE_USE_TEST_CUSTOMERS,
});

export const enableUseCouponCampaigns = () => ({
  type: ENABLE_USE_COUPON_CAMPAIGNS,
});

export const disableUseCouponCampaigns = () => ({
  type: DISABLE_USE_COUPON_CAMPAIGNS,
});
