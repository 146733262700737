import {
  addSingleLineItemToCart,
  createOrderFromCart,
  redemCoupon,
  setCartBillingAddress,
  setCartShippingAddress,
  setCurrentCartPaymentMethod,
  setCurrentCartShippingMethod
} from '../api/carts';

import { createCart } from '../api/carts';
import faker from 'faker';
import getLocaleCountry from './getLocaleCountry';
import getRandomElementFromArray from './getRandomElementFromArray';
import moment from 'moment';
import { showOrderByCartId } from '../api/orders';
import { getCouponCampaignCouponsCodes } from '../api/couponCampaigns';

const generateOrder = (shop, productList, paymentMethods, shippingZones) => {
  const locale = getLocaleCountry(shop.tax.country);
  const { serviceableCountries, shippingMethods } = getRandomElementFromArray(shippingZones);
  const shippingMethod = getRandomElementFromArray(shippingMethods);
  const paymentMethod = Array.isArray(paymentMethods) ? getRandomElementFromArray(paymentMethods) : paymentMethods;

  faker.locale = locale;

  const address = getAddress(getRandomElementFromArray(serviceableCountries));
  const productLineItems = orderProductLineItems(productList);
  const currency = shop.currencies[faker.datatype.number(0, shop.currencies.length)];
  const netTotalAmount = productLineItems.reduce((acc, curr) => acc + (curr.unitPrice.amount * curr.quantity), 0);

  return {
    orderNumber: `dummy-order-number-${Date.now()}-${faker.datatype.number({ min: 0, max: 99999 })}`,
    entryDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSSSSS'),
    customerEmail: `${faker.name.firstName().toLowerCase()}@example.com`,
    currency: currency,
    taxModel: shop.tax.taxModel,
    taxable: false,
    netTotal: {
      currency: currency,
      amount: netTotalAmount
    },
    taxTotal: {
      currency: currency,
      amount: 7.92,
    },
    taxes: [
      {
        taxClass: 'REGULAR',
        taxRate: 0.19,
        currency: currency,
        amount: 0.19,
      },
    ],
    subTotal: {
      currency: currency,
      amount: netTotalAmount,
    },
    discountedSubTotal: null,
    grandTotal: {
      currency: currency,
      amount: netTotalAmount,
    },
    productLineItems,
    couponLineItem: getCouponCode(),
    shippingLineItem: {
      lineItemPrice: {
        taxModel: 'GROSS',
        currency: currency,
        amount: 4.99,
      },
      initialLineItemPrice: {
        taxModel: 'GROSS',
        currency: currency,
        amount: 4.99,
      },
      taxClass: 'REGULAR',
      shippingMethod,
      lineItemTaxes: [
        {
          taxClass: 'REGULAR',
          taxRate: 0.19,
          currency: currency,
          amount: 0.7961,
        },
      ],
    },
    paymentLineItem: {
      lineItemPrice: {
        taxModel: 'GROSS',
        currency: currency,
        amount: 0,
      },
      initialLineItemPrice: {
        taxModel: 'GROSS',
        currency: currency,
        amount: 0,
      },
      taxClass: 'REGULAR',
      paymentMethod: {
        id: paymentMethod._id,
        name: paymentMethod.name,
        description: paymentMethod.description,
        discountOrFee: paymentMethod.discountOrFee,
        onlinePayment: paymentMethod.onlinePayment,
        refund: 'PARTIAL_REFUND',
      },
      lineItemTaxes: [
        {
          taxClass: 'REGULAR',
          taxRate: 0.19,
          currency: currency,
          amount: 0,
        },
      ],
    },
    shippingAddress: address,
    billingAddress: address,
    salesChannel: 'DummyGeneratorApp',
    adjustStock: false,
    testOrder: true,
    termsAndConditionsExplicitlyAccepted: false,
  };
};


export default generateOrder;


const getPaymentStatus = () => {
  const PAYMENT_STATUS = ['PAID', 'PARTIALLY_PAID', 'PENDING', 'REFUND_PENDING', 'VOIDED'];

  return getRandomElementFromArray(PAYMENT_STATUS);
};

export const getAddress = (countryCode) => {
  const birthdate = moment(faker.date.between(moment().year() - 70, moment().year() - 7)).format('YYYY-MM-DD');

  const address = {
    salutation: faker.name.prefix(),
    gender: getGender().toUpperCase(),
    company: faker.company.companyName(),
    title: faker.name.title(),
    firstName: faker.name.firstName(),
    middleName: faker.name.middleName(),
    lastName: faker.name.lastName(),
    street: faker.address.streetName(),
    houseNumber: faker.datatype.number(0, 35),
    street2: faker.address.secondaryAddress(),
    addressExtension: faker.address.streetPrefix(),
    postalCode: faker.address.zipCode(),
    dependentLocality: '',
    city: faker.address.city(),
    country: countryCode,
    state: faker.address.state(),
    email: `${faker.name.firstName().toLowerCase()}@example.com`,
    phone: faker.phone.phoneNumberFormat(),
    mobile: faker.phone.phoneNumberFormat(),
    vatId: null,
    taxNumber: null,
    birthDate: birthdate,
    displayAddressLines: [],
    _id: null,
  };

  address.displayAddressLines = [
    address.company,
    `${address.firstName} ${address.middleName} ${address.lastName}`,
    address.street,
    `${address.houseNumber} ${address.street2}`,
    address.postalCode,
    address.city,
    address.country,
  ];

  return address;
};

export const getAddressFromCustomer = (customer) => {
  const address = { ...customer.defaultShippingAddress }

  return address;
};

const orderProductLineItems = productList => {
  let availableProd = productList.filter(prod => ['IN_STOCK', 'LOW_STOCK'].includes(prod._embedded.availability.availabilityState));

  const MAX_NUMBER_PRODUCTS = availableProd.length > 9 ? faker.datatype.number({ min: 1, max: 9 }) : faker.datatype.number({ min: 1, max: availableProd.length })

  return Array(MAX_NUMBER_PRODUCTS).fill().map(_ => {
    const prodFromArr = getRandomElementFromArray(availableProd);

    availableProd = availableProd.filter(prod => prod._id !== prodFromArr._id);
    return getProd(prodFromArr);
  });
};

const getProd = prod => {
  const maxOrderQuantity = prod._embedded.availability.availableStock < prod.maxOrderQuantity ? prod._embedded.availability.availableStock : prod.maxOrderQuantity;
  const quantity = faker.datatype.number({ min: 1, max: maxOrderQuantity });
  console.log('Order prod', prod);
  const product = {
    quantity: quantity,
    unitPrice: {
      taxModel: prod.salesPrice.taxModel,
      currency: prod.salesPrice.currency,
      amount: prod.salesPrice.amount,
    },
    // unitDiscount: {
    //   currency: prod.salesPrice.currency,
    //   amount: -4.995, // Make random
    // },
    lineItemPrice: {
      taxModel: 'GROSS',
      currency: prod.salesPrice.currency,
      amount: 49.95,
    },
    product: {
      _id: prod._id,
      sku: prod.sku,
      name: prod.name,
      essentialFeatures: prod.essentialFeatures,
      shippingWeight: prod.shippingWeight,
      defaultImageDataUri: prod._links['default-image-metadata']?.href,
      variationAttributeValues: prod.variationAttributes,
      variationProductId: prod.variationAttributes.length > 0 ? prod.variations[0]._id : null,
    },
    lineItemTax: prod.refPrice.price.derivedPrice,
  };

  return product;
};

const getCouponCode = async (couponeCampaign) => {
  const code = await getCouponCampaignCouponsCodes(couponeCampaign._id);

  return getRandomElementFromArray(code.coupons).code;
};

const getGender = () => {
  const genders = ['UNDEFINED', 'FEMALE', 'MALE'];

  return getRandomElementFromArray(genders);
};


export const generateOrderFromCart = async (shop,
  productList,
  paymentMethods,
  shippingZones,
  couponCampaign=null,
  customer=null) => {

  const cart = await createCart();

  const productLineItems = orderProductLineItems(productList);

  for (let i = 0; i < productLineItems.length; i++) {
    const prod = productLineItems[i];

    await addSingleLineItemToCart(cart._id, prod.product._id, prod.quantity);
  }

  const { serviceableCountries, shippingMethods } = getRandomElementFromArray(shippingZones);
  const shippingMethod = getRandomElementFromArray(shippingMethods);
  const paymentMethod = Array.isArray(paymentMethods) ? getRandomElementFromArray(paymentMethods) : paymentMethods;

  // console.log(serviceableCountries)
  // console.log(shippingMethod);

  // const test = await listApplicableShippingMethodsForCurrentCart(cart._id);
  // console.log('shippingMethod', test)

  await setCurrentCartPaymentMethod(cart._id, paymentMethod._links['payment-method'].href);
  await setCurrentCartShippingMethod(cart._id, shippingMethod._links.self.href);

  const address = customer? getAddressFromCustomer(customer) : getAddress(getRandomElementFromArray(serviceableCountries));

  await setCartBillingAddress(cart._id, address);
  await setCartShippingAddress(cart._id, address);

  const order = {
    customerComment: 'Create with Dummy Generator',
    salesChannel: 'DummyGeneratorApp',
    // marketingChannel: 'Google Shopping',
    // marketingSubchannel: 'Summer Sale',
    testOrder: true,
    termsAndConditionsExplicitlyAccepted: true,
  };

  if (couponCampaign) {
    const code = await getCouponCode(couponCampaign);

    await redemCoupon(cart._id, code)
  }

  await createOrderFromCart(cart._id, order);

  return new Promise(resolve => {
    setTimeout(async () => {
      resolve(showOrderByCartId(cart._id));
    }, 2000);
  });
};

export const generateShippingProcess = order => {
  const code = faker.datatype.hexaDecimal(8);
  const lineItems = order.productLineItems.map(lineItem => {
    return { productLineItemId: lineItem._id, quantity: lineItem.quantity };
  });

  return {
    comment: 'Dummy delivery - handle with priority',
    sendMail: false,
    createPackingSlip: true,
    trackingCode: code,
    trackingLink: `http://example.com/track?code=${code}`,
    lineItems,
  };
};

export const generateMarkShipped = shippingProcess => {
  const trackingCode = shippingProcess.trackingCode;
  const trackingLink = shippingProcess.trackingLink;

  return {
    comment: 'Dummy shipping - Shipped by Dummy App',
    details: {
      trackingCode,
      trackingLink,
    }
  };
};
