import {
  GET_LOCATIONS_FAILURE,
  GET_LOCATIONS_SUCCESS,
  GET_TOTAL_LOCATIONS,
  SET_TOTAL_LOCATIONS,
  SET_USE_AS_PICKUP_OPTION,
} from '../actions/locations';

const initialState = {
  collections: [],
  totalLocations: 0,
  create: {
    use_as_pickup_option: false,
  }
};

const locationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        collections: action.payload
      };
    case GET_LOCATIONS_FAILURE:
      return {
        ...state,
        collections: []
      };
    case GET_TOTAL_LOCATIONS:
      return {
        ...state,
        totalLocations: action.payload
      };
    case SET_TOTAL_LOCATIONS:
      return {
        ...state,
        totalLocations: action.payload
      };
    case SET_USE_AS_PICKUP_OPTION:
      return {
        ...state,
        create: {
          use_as_pickup_option: action.payload
        }
      };
    default:
      return state;
  }
}

export default locationsReducer;
