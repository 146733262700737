import Div from './Div';
import { ReactComponent as ReactChecked } from '../assets/images/icons/checkbox_checked.svg';
import { ReactComponent as ReactUnchecked } from '../assets/images/icons/checkbox_unchecked.svg';
import faker from 'faker';

const CheckBox = ({ text, onChange, checked, style }) => {
  const id = `${Date.now()}-${faker.random.number()}`;
  return (
    <>
      <Div style={{ ...style }}>
        <div className="input__toggle">
          <input
            id={id}
            className="input__checkbox"
            type="checkbox"
            checked={checked || false}
            onChange={onChange}
            hidden="hidden"
          ></input>
          <label htmlFor={id} className="input__checkbox__control">
            <ReactChecked
              className="input__checkbox--checked"
              style={{ display: checked ? 'block' : 'none' }}
            ></ReactChecked>
            <ReactUnchecked
              className="input__checkbox--unchecked"
              style={{ display: checked ? 'none' : '' }}
            ></ReactUnchecked>
          </label>
        </div>
        <div>
          <label className="input__label" htmlFor={id}>
            {text}
          </label>
        </div>
      </Div>
    </>
  );
};

export default CheckBox;
