import moment from 'moment';

export const LOADING_START = 'LOADING_START';
export const LOADING_END = 'LOADING_END';
export const START_TIME_REQUEST = 'START_TIME_REQUEST';
export const END_TIME_REQUEST = 'END_TIME_REQUEST';
export const SET_TIME_TOTAL_REQUEST = 'SET_TIME_TOTAL_REQUEST';


export const loadingStart = () => ({
  type: LOADING_START,
});

export const loadingEnd = () => ({
  type: LOADING_END,
});

export const startTimeRequest = () => ({
  type: START_TIME_REQUEST,
  payload: parseInt(moment.utc().format('x'))
});

export const endTimeRequest = () => ({
  type: END_TIME_REQUEST,
  payload: parseInt(moment.utc().format('x'))
});

export const setTimeTotalRequest = () => ({
  type: SET_TIME_TOTAL_REQUEST
});
