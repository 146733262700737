import {
  GET_CUSTOMERS,
  GET_CUSTOMERS_FAILURE,
  GET_CUSTOMERS_SUCCESS,
  SET_TOTAL_CUSTOMERS
} from '../actions/customers';

const initialState = {
  collections: [],
  total: 0
}

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMERS:
      return {
        ...state,
        collections: []
      };
    case GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        collections: action.payload
      };
    case GET_CUSTOMERS_FAILURE:
      return {
        ...state,
        collections: []
      };
    case SET_TOTAL_CUSTOMERS:
      return {
        ...state,
        total: action.payload
      }
    default:
      return state;
  }
}

export default customerReducer;
