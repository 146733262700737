export const GET_CUSTOMERS = 'GET_CUSTOMERS'
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS';
export const GET_CUSTOMERS_FAILURE = 'GET_CUSTOMERS_FAILURE';

export const SET_TOTAL_CUSTOMERS = 'SET_TOTAL_CUSTOMERS'

export const getCustomersSuccess = (customers) => ({
  type: GET_CUSTOMERS_SUCCESS,
  payload: customers,
});

export const getCustomersFailure = (error) => ({
  type: GET_CUSTOMERS_FAILURE,
  payload: error,
});

export const getCustomers = () => ({
  type: GET_CUSTOMERS
});

export const setTotalCustomers = (total) => ({
  type: SET_TOTAL_CUSTOMERS,
  payload: total
});
