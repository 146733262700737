import apiCall from './apiCall';
import getArrayPages from '../utils/getArrayPages';

export const getCouponCampaigns = async total => {
  if (!total) total = await getTotalCouponCampaigns();

  const pages = getArrayPages(total, 1000);
  const couponCampaigns = await Promise.all(pages.map(async page => apiCall('GET', '/coupon-campaigns', {}, { page, size: 1000 })));

  return couponCampaigns.reduce((acum, data) => acum.concat(data.data._embedded.couponCampaigns), []);
};

export const getTotalCouponCampaigns = async () => {
  const resp = await apiCall('GET', '/coupon-campaigns', {}, { size: 1 });

  return resp.data.page.totalElements;
};

export const createCouponCampaign = async (coupon_campaign) => {
  const resp = await apiCall('POST', '/coupon-campaigns', JSON.stringify(coupon_campaign));

  return resp.data;
};

export const updateCouponCampaignStatus = async (couponCampaignId, status) => {
  const resp = await apiCall('PUT', `/coupon-campaigns/${couponCampaignId}/status`, JSON.stringify({ value: status}));

  return resp.data;
};

export const deleteCouponCampaign = async (couponCampaignId) => {
  const resp = await apiCall('DELETE', `/coupon-campaigns/${couponCampaignId}`);

  return resp.data;
};

export const updateCouponCampaignCouponsCodes = async (couponCampaignId, codes) => {
  const resp = await apiCall('PUT', `/coupon-campaigns/${couponCampaignId}/coupons`, JSON.stringify({ codes}));

  return resp.data;
};

export const getCouponCampaignCouponsCodes = async (couponCampaignId) => {
  const resp = await apiCall('GET', `/coupon-campaigns/${couponCampaignId}/coupons`);

  return resp.data._embedded;
};
