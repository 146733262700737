import Button from '../components/Button';
import Card from '../components/Card';
import InputField from '../components/InputField';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generateCustomer } from '../utils/generateCustomer';
import { createCustomer, deleteCustomer } from '../api/customer';

const CustomerContainer = ({ checkTimeRequest }) => {
  const { t } = useTranslation();
  const total = useSelector((state) => state.customers.total);
  const listCustomers = useSelector((state) => state.customers.collections);
  const shippingZones = useSelector((state) => state.shippingZones.collections);
  const paymentMethods = useSelector(
    (state) => state.paymentMethods.collections
  );

  const [customers, setCustomers] = useState(1);

  const onChangeCustomersHandler = (e) => {
    setCustomers(e.target.value);
  };

  const onCreateCustomerHandler = async () => {
    checkTimeRequest({ start: true });
    for (let i = 0; i < customers; i++) {
      const customer = await generateCustomer(paymentMethods, shippingZones);
      await createCustomer(customer);
    }
    checkTimeRequest({ start: false });
  };

  const onDeleteCustomerHandler = async () => {
    checkTimeRequest({ start: true });

    const arrPromises = listCustomers.map((customer) =>
      deleteCustomer(customer._id)
    );
    await Promise.all(arrPromises);

    checkTimeRequest({ start: false });
  };

  return (
    <>
      <Card title={t('customers.title')}>
        <InputField
          onChange={onChangeCustomersHandler}
          value={customers}
          min="1"
          max="1000"
          step="10"
        />
        <Button
          type="button"
          onClick={onCreateCustomerHandler}
          style="primary"
          margin="top"
        >
          {t('customers.actions.create')}
        </Button>

        <Button
          type="button"
          onClick={onDeleteCustomerHandler}
          style="danger"
          margin="left"
        >
          {t('customers.actions.delete')}
        </Button>
      </Card>
    </>
  );
};

export default CustomerContainer;
