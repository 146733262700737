import createOrder, {
  createCart,
  createShippingProcesses,
  listPaymentProcesses,
  markPaymentProcessAsPaid,
  markPaymentProcessAsViewed,
  markPaymentProcessAsVoided,
  markShipmentAsDelivered,
  markShipmentAsShipped,
  showActivePaymentProcessDetails,
} from '../api/orders';
import {
  disableOrderConfiguration,
  enableOrderConfiguration,
  setOrderPayment,
  enableUseTestCustomers,
  disableUseTestCustomers,
  enableUseCouponCampaigns,
  disableUseCouponCampaigns,
} from 'actions/orders';
import generateOrder, {
  generateMarkShipped,
  generateOrderFromCart,
  generateShippingProcess,
} from '../utils/generateOrder';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../components/Button';
import Card from '../components/Card';
import CheckBox from '../components/Checkbox';
import InputField from '../components/InputField';
import SelectField from '../components/SelectField';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import getRandomElementFromArray from '../utils/getRandomElementFromArray';

const OrderContainer = ({ checkTimeRequest }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const listCustomers = useSelector((state) => state.customers.collections);
  const shop = useSelector((state) => state.shop.data);
  const shippingZones = useSelector((state) => state.shippingZones.collections);
  const paymentMethods = useSelector(
    (state) => state.paymentMethods.collections
  );
  const orderPaymentMethod = useSelector(
    (state) => state.paymentMethods.selected
  );
  const total = useSelector((state) => state.products.totalProducts);
  const productList = useSelector((state) => state.products.collections);
  const isActiveOrderConfiguration = useSelector(
    (state) => state.orders.orderConfiguration.enabled
  );

  const isEnableUseCustomTest = useSelector(
    (state) => state.orders.orderConfiguration.useTestCustomers
  );

  const useCouponCampaign = useSelector(
    (state) => state.orders.orderConfiguration.useCouponCampaign
  );

  const [regularOrders, setRegularOrders] = useState(1);
  const [orderStatus, setOrderStatus] = useState('DELIVERED');

  const ORDER_STATUSES = useSelector((state) => state.orders.statuses);

  const couponCampaigns = useSelector((state) =>
    state.couponCampaigns.collections.filter((cc) => cc.status === 'ACTIVE')
  );

  const onCreateOrdersHandler = async () => {
    checkTimeRequest({ start: true });

    //const arr = [...Array(regularOrders).keys()].map((i) => {
    // const order = generateOrder(
    //   shop,
    //   productList,
    //   paymentMethods,
    //   shippingZones
    // );

    // createOrder(order);

    for (let i = 0; i < regularOrders; i++) {
      const couponCampaign = useCouponCampaign
        ? getRandomElementFromArray(couponCampaigns)
        : null;
      const customer =
        listCustomers.length > 0 && isEnableUseCustomTest
          ? getRandomElementFromArray(listCustomers)
          : null;
      const order = await generateOrderFromCart(
        shop,
        productList,
        orderPaymentMethod || paymentMethods,
        shippingZones,
        couponCampaign,
        customer
      );

      await markPaymentProcessAsViewed(order._id);

      if (orderStatus === 'PENDING') continue;

      const paymentProcess = await showActivePaymentProcessDetails(order._id);

      // TODO: Add notice to user that is necessary have a shipping zone with shop country

      await markPaymentProcessAsPaid(order._id, paymentProcess._id, {
        comment: 'Paid from Dummy App',
        details: {
          amount: {
            currency: order.grandTotal.currency,
            amount: order.grandTotal.amount,
          },
        },
      });

      if (orderStatus === 'PAID') continue;

      const shipingProcess = generateShippingProcess(order);

      const shippingProcess = await createShippingProcesses(
        order._id,
        shipingProcess
      );

      await markShipmentAsShipped(
        order._id,
        shippingProcess._id,
        generateMarkShipped(shippingProcess)
      );

      if (orderStatus === 'SHIPPED') continue;

      setTimeout(() => {
        markShipmentAsDelivered(order._id, shippingProcess._id, {
          comment: 'Delivered from Dummy App',
        });
      }, 2000);
    }
    //});

    // console.log(arr);

    checkTimeRequest({ start: false });
  };

  const onChangeOrdersHandler = (e) => {
    setRegularOrders(parseInt(e.target.value));
  };

  const onChangeOrderStatusHandler = (e) => {
    setOrderStatus(e.target.value);
  };
  const onChangeOrderPaymentHandler = (e) => {
    if (e.target.value === '') {
      dispatch(setOrderPayment(null));
    } else {
      dispatch(
        setOrderPayment(paymentMethods.find((p) => p.name === e.target.value))
      );
    }
  };
  const onChangeActiveOrderConfigurationHandler = (e) => {
    if (e.target.checked) {
      dispatch(enableOrderConfiguration());
    } else {
      dispatch(disableOrderConfiguration());
    }
  };

  const onChangeUseCustomerTestHandler = (e) => {
    if (e.target.checked) {
      dispatch(enableUseTestCustomers());
    } else {
      dispatch(disableUseTestCustomers());
    }
  };

  const onChangeUseCouponCampaignHandler = (e) => {
    if (e.target.checked) {
      dispatch(enableUseCouponCampaigns());
    } else {
      dispatch(disableUseCouponCampaigns());
    }
  };

  return (
    <>
      {total > 0 && (
        <Card title={t('order.title')}>
          <InputField
            onChange={onChangeOrdersHandler}
            value={regularOrders}
            min="1"
            max="10"
            step="1"
          />
          <CheckBox
            text={t('order.configuration.activate')}
            checked={isActiveOrderConfiguration}
            onChange={onChangeActiveOrderConfigurationHandler}
            style={{ marginTop: '10px' }}
          />
          {isActiveOrderConfiguration && (
            <>
              <SelectField
                includeBlank="true"
                label={t('order.configuration.status')}
                options={ORDER_STATUSES}
                value={orderStatus}
                handleChange={onChangeOrderStatusHandler}
              ></SelectField>
              <SelectField
                includeBlank="true"
                label={t('order.configuration.paymentMethod')}
                options={paymentMethods.map((payment) => payment.name)}
                value={orderPaymentMethod?.name}
                handleChange={onChangeOrderPaymentHandler}
              ></SelectField>
              <CheckBox
                text={t('order.configuration.useCouponCampaign')}
                checked={useCouponCampaign}
                onChange={onChangeUseCouponCampaignHandler}
                style={{ marginTop: '10px' }}
              />
              {/* <CheckBox
                text={t('order.configuration.testCustomers')}
                checked={isEnableUseCustomTest}
                onChange={onChangeUseCustomerTestHandler}
                style={{ marginTop: '10px' }}
              /> */}
            </>
          )}

          <Button
            type="button"
            onClick={onCreateOrdersHandler}
            style="primary"
            margin="top"
          >
            {t('order.actions.create')}
          </Button>
        </Card>
      )}
    </>
  );
};

export default OrderContainer;
