import {
  DISABLE_ORDER_CONFIGURATION,
  ENABLE_ORDER_CONFIGURATION,
  SET_ORDER_PAYMENT,
  SET_ORDER_STATUS,
  ENABLE_USE_TEST_CUSTOMERS,
  DISABLE_USE_TEST_CUSTOMERS,
  ENABLE_USE_COUPON_CAMPAIGNS,
  DISABLE_USE_COUPON_CAMPAIGNS
} from '../actions/orders';

const initState = {
  orderConfiguration: {
    enabled: false,
    status: '',
    payment: {},
    useTestCustomers: false
  },
  statuses: ['PENDING', 'PAID', 'SHIPPED', 'DELIVERED']
};

const orderReducer = (state = initState, action) => {
  switch (action.type) {
    case DISABLE_ORDER_CONFIGURATION:
      return {
        ...state,
        orderConfiguration: {
          enabled: false,
        }
      };
    case ENABLE_ORDER_CONFIGURATION:
      return {
        ...state,
        orderConfiguration: {
          enabled: true,
        }
      };
    case SET_ORDER_STATUS:
      return {
        ...state,
        orderConfiguration: {
          ...state.orderConfiguration,
          status: action.status,
        }
      };
    case SET_ORDER_PAYMENT:
      return {
        ...state,
        orderConfiguration: {
          ...state.orderConfiguration,
          payment: action.payment,
        }
      };
    case ENABLE_USE_TEST_CUSTOMERS:
      return {
        ...state,
        orderConfiguration: {
          ...state.orderConfiguration,
          useTestCustomers: true
        }
      }
    case DISABLE_USE_TEST_CUSTOMERS:
      return {
        ...state,
        orderConfiguration: {
          ...state.orderConfiguration,
          useTestCustomers: false
        }
      }
      case ENABLE_USE_COUPON_CAMPAIGNS:
      return {
        ...state,
        orderConfiguration: {
          ...state.orderConfiguration,
          useCouponCampaign: true
        }
      }
    case DISABLE_USE_COUPON_CAMPAIGNS:
      return {
        ...state,
        orderConfiguration: {
          ...state.orderConfiguration,
          useCouponCampaign: false
        }
      }
    default:
      return state;
  }
};

export default orderReducer;
