const Button = ({ type, onClick, children, style, margin = null }) => {
  const className = [`button__solid--${style}`];

  if(margin) className.push(`margin--${margin}`)
  return (
    <button type={type} onClick={onClick} className={className.join(' ')}>
      {children}
    </button>
  );
};

export default Button;
