export const FETCH_SHIPPING_ZONES = 'FETCH_SHIPPING_ZONES';
export const FETCH_SHIPPING_ZONES_SUCCESS = 'FETCH_SHIPPING_ZONES_SUCCESS';
export const FETCH_SHIPPING_ZONES_FAILURE = 'FETCH_SHIPPING_ZONES_FAILURE';

export const getShippingZones = () => ({
  type: FETCH_SHIPPING_ZONES,
});

export const getShippingZonesSuccess = (shippingZones) => ({
  type: FETCH_SHIPPING_ZONES_SUCCESS,
  payload: shippingZones,
});

export const getShippingZonesFailure = (error) => ({
  type: FETCH_SHIPPING_ZONES_FAILURE,
  payload: error,
});
