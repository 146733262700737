export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';

export const GET_TOTAL_PRODUCTS = 'GET_TOTAL_PRODUCTS';
export const SET_TOTAL_PRODUCTS = 'SET_TOTAL_PRODUCTS';

export const SET_TOTAL_VARIATIONS = 'SET_TOTAL_VARIATIONS';

export const SET_DELETE_PRODUCTS_BATCH_ACTIONS = 'SET_DELETE_PRODUCTS_BATCH_ACTIONS';

export const SET_DELETE_TEST_PRODUCTS = 'SET_DELETE_TEST_PRODUCTS';


export const getProductsSuccess = (products) => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: products,
});

export const getProductsFailure = (error) => ({
  type: GET_PRODUCTS_FAILURE,
  payload: error,
});

export const getProducts = () => ({
  type: GET_PRODUCTS
});

export const getTotalProducts = () => ({
  type: GET_TOTAL_PRODUCTS
});

export const setTotalProducts = (total) => ({
  type: SET_TOTAL_PRODUCTS,
  payload: total,
});

export const setTotalVariations = (total) => ({
  type: SET_TOTAL_VARIATIONS,
  payload: total,
});

export const setDeleteProductsBatchActions = (actions) => ({
  type: SET_DELETE_PRODUCTS_BATCH_ACTIONS,
  payload: actions,
});

export const setDeleteTestProducts = (actions) => ({
  type: SET_DELETE_TEST_PRODUCTS,
  payload: actions,
});
