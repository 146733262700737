const Card = ({ children, title }) => {
  return (
    <div className="card card--padding">
      <h5 className="card__headline">{title}</h5>
      <div className="card-block">{children}</div>
    </div>
  );
};

export default Card;
