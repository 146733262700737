export const GET_LOCATIONS = 'GET_LOCATIONS';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_FAILURE = 'GET_LOCATIONS_FAILURE';

export const GET_TOTAL_LOCATIONS = 'GET_TOTAL_LOCATIONS';
export const SET_TOTAL_LOCATIONS = 'SET_TOTAL_LOCATIONS';

export const SET_USE_AS_PICKUP_OPTION = 'SET_USE_AS_PICKUP_OPTION';

export const getLocationsSuccess = (locations) => ({
  type: GET_LOCATIONS_SUCCESS,
  payload: locations,
});

export const getLocationsFailure = (error) => ({
  type: GET_LOCATIONS_FAILURE,
  payload: error,
});

export const getLocations = () => ({
  type: GET_LOCATIONS
});

export const getTotalLocations = () => ({
  type: GET_TOTAL_LOCATIONS
});

export const setTotalLocations = (total) => ({
  type: SET_TOTAL_LOCATIONS,
  payload: total,
});

export const setUseAsPickupOption = (actions) => ({
  type: SET_USE_AS_PICKUP_OPTION,
  payload: actions,
});
