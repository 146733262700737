import apiCall from './apiCall';

export const getLocations = async () => {
  const locations = await apiCall('GET', '/shop/locations');

  return locations.data._embedded.locations;
};

export const getTotalLocations = async () => {
  const resp = await apiCall('GET', '/shop/locations', {}, { size: 1 });

  return resp.data.page.totalElements;
};

export const createLocation = async (location) => {
  const resp = await apiCall('POST', '/shop/locations', JSON.stringify(location));

  return resp.data;
};

export const deleteLocation = async (locationId) => {
  const resp = await apiCall('DELETE', `/shop/locations/${locationId}`);

  return resp.data;
};
