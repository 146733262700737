export const PAYMENT_REQUEST = 'PAYMENT_REQUEST';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_FAILURE = 'PAYMENT_FAILURE';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';

export const paymentRequest = () => ({
  type: PAYMENT_REQUEST,
});

export const paymentSuccess = (payments) => ({
  type: PAYMENT_SUCCESS,
  payload: payments,
});

export const paymentFailure = (error) => ({
  type: PAYMENT_FAILURE,
  payload: error,
});
