import apiCall from './apiCall';

export const createCart = async () => {
  const resp = await apiCall('POST', '/carts');

  return resp.data;
};

export const addSingleLineItemToCart = async (cartId, productId, quantity) => {
  const body = {
    _type: 'PRODUCT',
    _ref: productId,
    quantity,
  };
  const resp = await apiCall('POST', `/carts/${cartId}/line-items`, JSON.stringify(body));

  return resp.data;
};

export const setCartShippingAddress = async (cartId, address) => {
  const resp = await apiCall('PUT', `/carts/${cartId}/shipping-address`, JSON.stringify(address));

  return resp.data;
};

export const setCartBillingAddress = async (cartId, address) => {
  const resp = await apiCall('PUT', `/carts/${cartId}/billing-address`, JSON.stringify(address));

  return resp.data;
};

export const listApplicablePaymentMethodsForCurrentCart = async (cartId) => {
  const resp = await apiCall('GET', `/carts/${cartId}/payment-methods`, {}, {});

  return resp.data;
};

export const listApplicableShippingMethodsForCurrentCart = async (cartId) => {
  const resp = await apiCall('GET', `/carts/${cartId}/shipping-methods`, {}, {});

  return resp.data;
};

export const setCurrentCartPaymentMethod = async (cartId, paymentMethodUrl) => {
  const resp = await apiCall('PUT', `/carts/${cartId}/payment-methods/current`, paymentMethodUrl, {}, 'text/uri-list');

  return resp.data;
};

export const setCurrentCartShippingMethod = async (cartId, shippingMethodUrl) => {
  const resp = await apiCall('PUT', `/carts/${cartId}/shipping-methods/current`, shippingMethodUrl, {}, 'text/uri-list');

  return resp.data;
};

export const createOrderFromCart = async (cartId, body) => {
  const resp = await apiCall('POST', `/carts/${cartId}/order`, JSON.stringify(body));

  return resp.data;
};

export const redemCoupon = async (cartId, coupon) => {
  const resp = await apiCall('POST', `/carts/${cartId}/coupon`, JSON.stringify({code: coupon}));

  return resp.data;
}
