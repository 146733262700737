import faker from 'faker';
import generateColorImage from './generateColorImage';
import generateRandomPrice from './generateRandomPrice';
import getRandomElementFromArray from './getRandomElementFromArray';

const generateProduct = (shop, isVariation = false) => {
  const SKU = generateSKU();
  const currency = getRandomCurrency(shop.currencies);
  const taxClass = getRandomTaxClass(shop.tax.supportedTaxClasses);
  const manufacturerPrice = generateRandomPrice(1, 1000);
  const listPrice = manufacturerPrice;
  const salesPrice = generateRandomPrice(0.1, listPrice);

  return {
    sku: isVariation ? null : SKU,
    name: `Dummy ${SKU}`,
    description: faker.lorem.paragraph(),
    manufacturer: faker.lorem.words(),
    essentialFeatures: faker.lorem.words(),
    tags: [
      'Dummy',
      faker.lorem.word(),
      faker.lorem.word(),
      faker.lorem.word(),
    ],
    productIdentifiers: [
      {
        type: 'EAN',
        value: faker.datatype.number(),
      },
    ],
    salesPrice: {
      taxModel: shop.tax.taxModel,
      amount: salesPrice,
      currency: currency,
    },
    listPrice: {
      taxModel: shop.tax.taxModel,
      amount: listPrice,
      currency: currency,
    },
    manufacturerPrice: {
      taxModel: shop.tax.taxModel,
      amount: manufacturerPrice,
      currency: currency,
    },
    visible: true,
    taxClass: taxClass,
    specifiedShippingWeight: {
      value: faker.datatype.number({ min: 1, max: 10000 }),
      displayUnit: getWeightUnit()
    },
    maxOrderQuantity: faker.datatype.number({ min: 1, max: 20 }),
    shippingDimension: {
      length: faker.datatype.number({ min: 1, max: 10000 }),
      width: faker.datatype.number({ min: 1, max: 10000 }),
      height: faker.datatype.number({ min: 1, max: 10000 })
    },
    refPrice: {
      refQuantity: faker.datatype.number({ min: 1, max: 10000 }),
      unit: getRefPriceUnit(),
      quantity: salesPrice,
      price: {
        taxModel: shop.tax.taxModel,
        amount: generateRandomPrice(salesPrice, listPrice),
        currency: currency,
      },
    },
    shippingPeriod: {
      min: faker.datatype.number({ min: 1, max: 10 }),
      max: faker.datatype.number({ min: 10, max: 30 }),
      displayUnit: getShippingUnit(),
    },
    pickupPeriod: {
      min: faker.datatype.number({ min: 1, max: 10 }),
      max: faker.datatype.number({ min: 10, max: 30 }),
      displayUnit: getPickUpUnits(),
    },
    variationAttributes: isVariation ? generateVariationProduct() : [],
  };
};

const generateVariationProduct = () => {
  return [...Array(faker.datatype.number({ min: 1, max: 3 })).keys()].map(() => generateVariationProperty());
};

// Function to generate a SKU ramdomly with date
const generateSKU = () => {
  return `${Date.now()}-${faker.datatype.number()}-${faker.datatype.number()}-${faker.datatype.number()}`;
};

// Function to get a ramdom currency receiving an array of currencies
const getRandomCurrency = (currencies) => {
  const currency = currencies[Math.floor(Math.random() * currencies.length)];
  return currency;
};

// Function to get a ramdom tax model receiving an array of tax classes
const getRandomTaxClass = (taxClasses) => {
  const taxClass = taxClasses[Math.floor(Math.random() * taxClasses.length)];
  return taxClass;
};

export const generateImage = (name =  faker.lorem.word()) => {
  const width = 1100,
    height = 1100,
    canvas = document.createElement('canvas');

  const { background, font } = generateColorImage();

  canvas.height = height;
  canvas.width = width;
  const context = canvas.getContext('2d');

  context.fillStyle = background;
  context.fillRect(0, 0, width, height);

  context.fillStyle = font;
  context.font = '100px Courier';
  context.textAlign = 'center';
  context.textBaseline = 'middle';
  context.fillText(`${name}`, (width / 2), (height / 2));

  return canvas;
};

export default generateProduct;


export const generateStock = () => {
  return {
    initialAvailableStock: faker.datatype.number({ min: 0, max: 200 }),
    stockThreshold: faker.datatype.number({ min: 1, max: 50 }),
  };
};

export const adjustAvailableStock = (stock) => {
  const adjust = { relativeAmount: faker.datatype.number({ min: -stock.initialAvailableStock, max: 100 }) };

  return adjust;
};

export const generateVariationProperty = () => {
  const valuesSize = faker.datatype.number({ min: 2, max: 5 });

  const values = [...Array(valuesSize).keys()].map(() => faker.lorem.word());

  return {
    displayName: faker.lorem.words(),
    values: [...new Set(values)]
  };

};

const getRefPriceUnit = () => {
  const UNITS = ['GRAM', 'KILOGRAM', 'MILLILITER', 'LITER', 'METER', 'SQUAREMETER', 'CUBICMETER'];

  return getRandomElementFromArray(UNITS);
};

const getShippingUnit = () => {
  const UNITS = ['DAYS', 'WEEKS', 'MONTHS'];

  return getRandomElementFromArray(UNITS);
};

const getWeightUnit = () => {
  const UNITS = ['GRAMS', 'KILOGRAMS'];

  return getRandomElementFromArray(UNITS);
};

const getPickUpUnits = () => {
  const UNITS = ['MINUTES', 'HOURS', 'DAYS', 'WEEKS', 'MONTHS'];

  return getRandomElementFromArray(UNITS);
};
