import { combineReducers } from 'redux';
import customerReducer from './customers';
import orderReducer from './orders';
import paymentReducer from './payment';
import productsReducer from './products';
import requestReducer from './request';
import shippingZoneReducer from './shippingZone';
import shopReducer from './shop';
import locationsReducer from './locations';
import couponCampaignsReducer from './couponCampaigns';

const allReducers = combineReducers({
  shop: shopReducer,
  request: requestReducer,
  paymentMethods: paymentReducer,
  shippingZones: shippingZoneReducer,
  products: productsReducer,
  orders: orderReducer,
  locations: locationsReducer,
  customers: customerReducer,
  couponCampaigns: couponCampaignsReducer
})

export default allReducers;
