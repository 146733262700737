
export const GET_SHOP_DATA = 'GET_SHOP_DATA';
export const SHOP_DATA_SUCCESS = 'SHOP_DATA_SUCCESS';
export const SHOP_DATA_FAILURE = 'SHOP_DATA_FAILURE';

export const getShopData = () => {
  return {
    type: GET_SHOP_DATA,
  }
}

export const shopDataSuccess = shop => {
  return {
    type: SHOP_DATA_SUCCESS,
    payload: shop
  };
}

export const shopDataFailure = error => {
  return {
    type: SHOP_DATA_FAILURE,
    payload: error
  };
}
