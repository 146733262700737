import apiCall, { apiCallRetry } from './apiCall';

export const createOrder = async order => {
  const resp = await apiCall('POST', '/orders', JSON.stringify(order));

  return resp.data;
};

export const listPaymentProcesses = async (orderId) => {
  const resp = await apiCall('GET', `/orders/${orderId}/processes/payments`, {}, {});

  return resp.data;
};

export const markPaymentProcessAsVoided = async (orderId, paymentProcessId, body) => {
  const resp = await apiCall('POST', `/orders/${orderId}/processes/payments/${paymentProcessId}/mark-voided`, JSON.stringify(body), {});

  return resp.data;
};

export const markPaymentProcessAsPaid = async (orderId, paymentProcessId, body) => {
  const resp = await apiCall('POST', `/orders/${orderId}/processes/payments/${paymentProcessId}/mark-paid`, JSON.stringify(body), {});

  return resp.data;
};

export const markPaymentProcessAsViewed = async (orderId) => {
  const resp = await apiCall('POST', `/orders/${orderId}/mark-as-viewed`, {}, {});

  return resp.data;
};


export const showOrderByCartId = async (cartId) => {
  const resp = await apiCallRetry('GET', `/orders/search/find-by-cart-id?cartId=${cartId}`, {}, {});

  return resp.data;
}

export const showActivePaymentProcessDetails = async (orderId) => {
  const resp = await apiCall('GET', `/orders/${orderId}/processes/payments/active`, {}, {});

  return resp.data;
};

export const createShippingProcesses = async (orderId, body) => {
  const resp = await apiCall('POST', `/orders/${orderId}/processes/shippings`, JSON.stringify(body), {});

  return resp.data;
};

export const markShipmentAsShipped = async (orderId, shippingId, body) => {
  const resp = await apiCall('POST', `/orders/${orderId}/processes/shippings/${shippingId}/mark-shipped`, JSON.stringify(body), {});

  return resp.data;
};

export const markShipmentAsDelivered = async (orderId, shippingId, body) => {
  const resp = await apiCall('POST', `/orders/${orderId}/processes/shippings/${shippingId}/mark-delivered`, JSON.stringify(body), {});

  return resp.data;
};


export default createOrder;
