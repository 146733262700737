import Div from './Div';

const InputField = (props) => {
  return (
    <>
      <Div>
        <input type="number" {...props} />
      </Div>
    </>
  );
};

export default InputField;
