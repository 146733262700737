import {
  PAYMENT_FAILURE,
  PAYMENT_REQUEST,
  PAYMENT_SUCCESS,
} from '../actions/payment';

const initialState = {
  collections: []
}

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAYMENT_REQUEST:
      return {
        ...state,
        collections: []
      };
    case PAYMENT_SUCCESS:
      return {
        ...state,
        collections: action.payload
      };
    case PAYMENT_FAILURE:
      return {
        ...state,
        collections: []
      };
    default:
      return state;
  }
}

export default paymentReducer;
