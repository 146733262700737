import faker from 'faker';
import moment from 'moment';

import getRandomElementFromArray from './getRandomElementFromArray';
import { createCart, setCartBillingAddress, setCartShippingAddress } from '../api/carts';

import { getAddress } from './generateOrder';

export const generateCustomer = async (paymentMethods, shippingZones) => {
  let cart = await createCart();

  const { serviceableCountries, shippingMethods } = getRandomElementFromArray(shippingZones);
  const shippingMethod = getRandomElementFromArray(shippingMethods);
  const paymentMethod = Array.isArray(paymentMethods) ? getRandomElementFromArray(paymentMethods) : paymentMethods;

  const address = getAddress(getRandomElementFromArray(serviceableCountries));

  await setCartBillingAddress(cart._id, address);
  cart = await setCartShippingAddress(cart._id, address);

  return {
    // defaultPaymentMethodId: shippingMethod._id,
    // defaultShippingMethodId: paymentMethod._id,
    billingAddress: cart.billingAddress,
    email: `${faker.name.firstName().toLowerCase()}_${Date.now()}@example.com`,
    customerComment: 'Is gonna be fun',
    shippingAddress: cart.shippingAddress,
    testCustomer: true,
    customerOrigin: 'GUEST'
  };
}
