import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Info = () => {
  const { t } = useTranslation();
  const time = useSelector((state) => state.request.time);
  const totalProducts = useSelector((state) => state.products.totalProducts);
  const totalVariations = useSelector(
    (state) => state.products.totalVariations
  );

  return (
    <div>
      <label>
        {t('info.label.total', {
          totalProducts: totalProducts - totalVariations,
          totalVariations,
        })}
      </label>
      <br />
      {!isNaN(time.total) && (
        <label>
          {t('info.label.time', { time: time.total, display: time.display })}
        </label>
      )}
    </div>
  );
};

export default Info;
